import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Question from '../views/Question.vue'
import Result from '../views/Result.vue'
import Infos from '../views/Infos.vue'
import Intro from '../views/Intro.vue'
import Form from '../views/Form.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/questions/:type',
    name: 'Question',
    component: Question,
    props: true
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '/infos',
    name: 'Infos',
    component: Infos
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router

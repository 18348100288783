<template>
  <v-content class="backgroundImg" data-triangle-container>
    <v-container>
      <div class="ml-5 mx-xl-12 px-xl-12 pt-6 d-flex align-center">
        <p style="color:#62579d" class="display-1 font-weight-medium mb-0">
          Résultats
        </p>
      </div>
      <div
        class="mt-6 mx-xl-12 px-xl-12 my-12 mb-0 pb-5 pb-2 pb-sm-10 py-10 align-center"
      >
        <v-row class="mt-12">
          <v-col cols="12" sm="12" md="12" lg="7">
            <p class="text-center mt-7 mb-10"></p>
            <p class="text-center">
              <Triangle
                :width="width"
                mr="100"
                ml="95"
                mb="80"
                mt="100"
                :p1="p1"
                :p2="p2"
                :p3="p3"
                :key="componentKey"
              />
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="5">
            <v-card tile class="ma-3 ma-sm-8 py-4" v-if="note">
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/enfant.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Enfant</strong><br />
                  <strong>{{ note.enfant }} points / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant >= 7 && note.enfant <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant >= 13"
                ></v-img>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/environnement.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Environnement</strong><br />
                  <strong>{{ note.environnement }} points / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement >= 7 && note.environnement <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement >= 13"
                ></v-img>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/ecrans.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Ecrans</strong><br />
                  <strong>{{ note.ecrans }} points / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans >= 7 && note.ecrans <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans >= 13"
                ></v-img>
              </v-card-text>
              <div
                class="mx-7 my-3 pt-5"
                style="border-top: 1px solid #62579d;"
              >
                <h2 class="mb-4 mt-4">Nos conseils</h2>
                <p style="color:#333333;">
                  La gestion des écrans peut être évaluée sous 3 angles: le
                  <strong>pôle enfant</strong>, en adaptant les règles à la
                  personnalité et à l'âge de votre enfant,
                  <strong>le pôle environnement</strong>, en étant attentif aux
                  lieux ou à la manière d'utiliser les écrans, et le
                  <strong>pôle écrans</strong>, en réfléchissant aux nombres de
                  ces derniers et à leur contenu.<br /><strong
                    class="mt-3 d-flex"
                    >Suite à vos résultats, nous vous invitons à lire les
                    conseils du pôle {{ poleTxt }}</strong
                  ><br /><v-btn
                    href="/infos"
                    color="#62579d"
                    class="white--text mt-4"
                    >Lire les conseils</v-btn
                  >
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="my-12 mx-xl-12 px-xl-12 ml-0 mt-0">
        <v-card class="ma-3 ma-sm-8 my-12 mt-0 px-8 py-5">
          <h2>...Et refaites le test dans quelques mois !</h2>
          <p class="pa-0" style="color:#333333;">
            Nous vous félicitons d'avoir pris le temps de passer ce test. Cela
            démontre votre intérêt pour la problématique des écrans, mais
            surtout pour le bien-être de votre enfant. Nous espérons qu'il vous
            sera utile pour la suite dans votre rôle de parents ! Pour plus
            d'informations sur le sujet, consultez notre site
            <a href="https://www.prevention-ecrans.ch" target="_blank"
              >prevention-ecrans.ch</a
            >. Vous pourrez également nous solliciter en cas de questions.
          </p>
        </v-card>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import apiCall from '@/utils/api.js'
import Triangle from '@/components/Triangle'
export default {
  name: 'Home',
  data() {
    var width = 600
    if(document.querySelector('body').offsetWidth < 600){
      width = 300
    }
    return {
      pole: '',
      x: 0,
      y: 0,
      r: 200,
      p1: 0,
      p2: 0,
      p3: 0,
      width: width,
      componentKey: 1,
      advises: [],
      note: [],
      loading: true,
      poleTxt: ''
    }
  },
  mounted() {

    apiCall({
      url:
        '/responses?order[note]=asc&feedback=' +
        this.$store.getters.getFeedback,
      method: 'get'
    }).then(resp => {

      let note = []
      let nb = []

      note['ecrans'] = 0
      note['enfant'] = 0
      note['environnement'] = 0

      nb['ecrans'] = 0
      nb['enfant'] = 0
      nb['environnement'] = 0

      resp.data.forEach(response => {
        note[response.category] += response.note
        nb[response.category]++
      })

      this.p1 = note['environnement'] / (4 * nb['environnement'])
      this.p2 = note['ecrans'] / (4 * nb['ecrans'])
      this.p3 = note['enfant'] / (4 * nb['enfant'])

      if (this.p1 <= this.p2 && this.p1 < this.p3) {
        this.pole = 'environnement'
        this.poleTxt = 'environnement'
      } else if (this.p2 <= this.p1 && this.p2 < this.p3) {
        this.pole = 'ecrans'
        this.poleTxt = 'écrans'
      } else {
        this.pole = 'enfant'
        this.poleTxt = 'enfant'
      }

      this.note = note

      this.componentKey = 2

      // apiCall({
      //   url: '/feedback/' + this.$store.getters.getFeedback,
      //   method: 'put',
      //   data: { status: 1 }
      // }).then(resp => {
      //   this.loading = false
      //   if (
      //     document.querySelector('[data-triangle-container]').offsetWidth < 600
      //   ) {

      //      this.width = parseInt(
      //        document.querySelector('[data-triangle-container]').offsetWidth *
      //          0.8
      //     )
      //   }
      // })
    })
  },
  components: {
    Triangle
  }
}
</script>

<template>
    <v-content class="backgroundImg">
      <v-container class="d-flex align-center justify-center" style="height: 100%">
        <div class="ma-3 ma-sm-12 px-sm-7 py-10 d-flex flex-column justify-center align-center white--text">
          <h1 color="primary" class="text-center title-page">Quelques informations avant de débuter</h1> 
          <ul class="pl-0">
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Les 3e ont été pensés en particulier pour des parents d'enfants de 5 à 10 ans. </p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Si vous avez plusieurs enfants, faites un test pour chacun d'entre eux.</li>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Le terme « écrans » comprend la télévision, les ordinateurs, les smartphones, les tablettes et les consoles de jeux.</p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Les 3e vous permettent de faire un état des lieux concernant la gestion des écrans à la maison.</p> 
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Ce test est strictement anonyme.</p>
            <p class="text-center mt-5 mb-20 font-weight-bold" style="font-size: 18px">Il est compliqué de gérer tous ces écrans qui nous entourent. Chacun fait de son mieux dans sa situation qui est la sienne. Nous vous félicitons de démarrer ce test. Cela prouve que vous vous intéressez à l’éducation aux écrans.</p>
          </ul>
          
          <v-btn
            class="my-10"
            color="primary"
            :to="{name:'Form'}"
            dark
            x-large
            >
            Suivant
          </v-btn>
          
        </div>
      </v-container>
    </v-content>
</template>
<script>
export default {
  name: 'Home'
}
</script>

<style scope>
h1,
h2,
p {
  color: #62579d;
}
@media screen and (max-width: 599px) {
  .title-page {
    font-size: 25px !important;
  }
}
</style>
<template>
  <v-content class="backgroundImg">
    <v-container v-if="!$route.query.pole">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous
          donnez à vos enfants.
        </h1>
        <div class="enfant mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            enfant
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong class="color-enfant mt-2" style="display:inline-block;"
              >Soyez avec.</strong
            >
            Être avec votre enfant, jouer avec, vous permet de nourrir le lien
            que vous avez avec lui. Il se sent plus en sécurité, vous apprenez à
            le connaître et vous le voyez évoluer dans toutes ses étapes de vie.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Proposez à votre enfant des activités de mouvement.</strong
            >
            Devant l'écran, votre enfant est immobile. Pourtant, le mouvement
            est capital pour votre enfant et sa santé.<br />
            <strong
              class="color-enfant mt-2" style="display:inline-block;">Chaque enfant est différent. </strong> Votre enfant est assez impulsif? Il sera important de
            bien encadrer sa pratique des écrans. Votre enfant respecte
            généralement bien les limites que vous lui donnez? Une gestion
            relativement autonome de son utilisation des écrans est peut-être à
            essayer. <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Habituez votre enfant à avoir une bonne position devant les
              écrans (se tenir droit, distance, etc.).</strong
            >
            À la longue, une position inadéquate devant les écrans peut
            entraîner des problèmes de santé (douleurs cervicales, maux de dos,
            fatigue des yeux). <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Montrez à votre enfant ces bonnes attitudes.</strong
            >
            Pour soutenir toutes les autres actions que vous avez mises en
            place, soyez cohérent avec votre propre manière d'utiliser les
            écrans. Votre enfant reproduit plus ce que vous faites, que ce que
            vous dites.
          </p>
        </div>
        <div class="environnement mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            environnement
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Posez un cadre clair à votre enfant.</strong
            >
            Posez des règles simples qui définissent combien de temps, dans quel
            lieu et comment votre enfant va pouvoir utiliser tous les types
            d'écrans qui co-existent à la maison. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Placez les écrans dans un lieu commun.</strong
            >
            Votre enfant est plus en sécurité dans sa chambre si l'écran n'y est
            pas. Son sommeil gagnera en qualité. À table, privilégiez un moment
            déconnecté. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Choisissez un bon moment pour l'utilisation des écrans.</strong
            >
            S'il fait beau ou si votre enfant a encore des devoirs à faire, il
            vaut mieux remettre les écrans à plus tard. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Utilisez plutôt les écrans en famille ou entre ami-e-s.</strong
            >
            Quand on évoque les risques avec les écrans, l'isolement arrive dans
            les premiers. Car les écrans sont conçus pour accaparer toute notre
            attention. Utiliser les écrans en famille ou avec des ami-e-s et
            partager ces moments maintiennent les relations avec les autres.
            <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Offrez-vous des moments déconnectés.</strong
            >
            Dormir, manger, jouer, lire, sans les écrans allumés à proximité,
            permet de profiter pleinement de ces moments-là. De plus, ces
            espaces déconnectés nous montrent à tous que nous pouvons vivre
            sans.
          </p>
        </div>
        <div class="ecran mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            écrans
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong class="color-ecrans mt-2" style="display:inline-block;"
              >Intéressez-vous à ce que votre enfant regarde et fait sur les
              écrans.</strong
            >
            Montrer de l'intérêt pour les activités que pratique votre enfant
            est une excellente manière de garder le lien et de dialoguer avec
            lui. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Vérifiez que les contenus et le type d'écrans soient bien adaptés
              à l'âge de votre enfant.</strong
            >
            Votre enfant n'est pas en mesure de juger si un jeu, un film ou un
            type d'écrans est adapté à son âge. Pour les âges, il existe
            plusieurs repères d'indication (par exemple les normes PEGI pour les
            jeux vidéo). Pour les types d'écrans, le smartphone n'est pas un
            objet pour les tout-petits (0-6 ans). <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Privilégiez l'utilisation d'écrans où votre enfant est
              actif.</strong
            >
            Le cerveau de votre enfant se développe à travers des activités qui
            animent tous ses sens. Regarder un écran passivement ne favorise pas
            ce développement. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Aidez votre enfant à se concentrer sur une seule activité à la
              fois.</strong
            >
            Les écrans donnent l'impression d'être capable de faire plusieurs
            choses en même temps efficacement. Ceci est un mythe. Il est
            important que votre enfant, dès son plus jeune âge, dédie son
            attention à une seule activité. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Limitez l'accès et le nombre d'écrans.</strong
            >
            Les écrans sont en général très attirants pour votre enfant. Ceux
            qui sont portables encore plus, car ils peuvent les emporter
            partout. Limiter le nombre disponible d'écrans à la maison, c'est
            favoriser leur attention sur d'autres types d'activités.
          </p>
        </div>
      </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'enfant'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous donnez
          à vos enfants.
        </h1>
        <div class="enfant mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
            enfant
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong class="color-enfant mt-2" style="display:inline-block;"
              >Soyez avec.</strong
            >
            Être avec votre enfant, jouer avec, vous permet de nourrir le lien
            que vous avez avec lui. Il se sent plus en sécurité, vous apprenez à
            le connaître et vous le voyez évoluer dans toutes ses étapes de vie.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Proposez à votre enfant des activités de mouvement.</strong
            >
            Devant l'écran, votre enfant est immobile. Pourtant, le mouvement
            est capital pour votre enfant et sa santé.
            <br />
            <strong
              class="color-enfant mt-2" style="display:inline-block;">Chaque enfant est différent. </strong>
            Votre enfant est assez impulsif? Il sera important de
            bien encadrer sa pratique des écrans. Votre enfant respecte
            généralement bien les limites que vous lui donnez? Une gestion
            relativement autonome de son utilisation des écrans est peut-être à
            essayer. <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Habituez votre enfant à avoir une bonne position devant les
              écrans (se tenir droit, distance, etc.).</strong
            >
            À la longue, une position inadéquate devant les écrans peut
            entraîner des problèmes de santé (douleurs cervicales, maux de dos,
            fatigue des yeux). <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Montrez à votre enfant ces bonnes attitudes.</strong
            >
            Pour soutenir toutes les autres actions que vous avez mises en
            place, soyez cohérent avec votre propre manière d'utiliser les
            écrans. Votre enfant reproduit plus ce que vous faites, que ce que
            vous dites.
          </p>
        </div>
       </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'ecrans'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous donnez
          à vos enfants.
        </h1>
        <div class="ecran mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
            écrans
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong class="color-ecrans mt-2" style="display:inline-block;"
              >Intéressez-vous à ce que votre enfant regarde et fait sur les
              écrans.</strong
            >
            Montrer de l'intérêt pour les activités que pratique votre enfant
            est une excellente manière de garder le lien et de dialoguer avec
            lui. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Vérifiez que les contenus et le type d'écrans soient bien adaptés
              à l'âge de votre enfant.</strong
            >
            Votre enfant n'est pas en mesure de juger si un jeu, un film ou un
            type d'écrans est adapté à son âge. Pour les âges, il existe
            plusieurs repères d'indication (par exemple les normes PEGI pour les
            jeux vidéo). Pour les types d'écrans, le smartphone n'est pas un
            objet pour les tout-petits (0-6 ans). <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Privilégiez l'utilisation d'écrans où votre enfant est
              actif.</strong
            >
            Le cerveau de votre enfant se développe à travers des activités qui
            animent tous ses sens. Regarder un écran passivement ne favorise pas
            ce développement. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Aidez votre enfant à se concentrer sur une seule activité à la
              fois.</strong
            >
            Les écrans donnent l'impression d'être capable de faire plusieurs
            choses en même temps efficacement. Ceci est un mythe. Il est
            important que votre enfant, dès son plus jeune âge, dédie son
            attention à une seule activité. <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Limitez l'accès et le nombre d'écrans.</strong
            >
            Les écrans sont en général très attirants pour votre enfant. Ceux
            qui sont portables encore plus, car ils peuvent les emporter
            partout. Limiter le nombre disponible d'écrans à la maison, c'est
            favoriser leur attention sur d'autres types d'activités.
          </p>
        </div>
        
        </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'environnement'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous donnez
          à vos enfants.
        </h1>

        <div class="environnement mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
            environnement
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Posez un cadre clair à votre enfant.</strong
            >
            Posez des règles simples qui définissent combien de temps, dans quel
            lieu et comment votre enfant va pouvoir utiliser tous les types
            d'écrans qui co-existent à la maison. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Placez les écrans dans un lieu commun.</strong
            >
            Votre enfant est plus en sécurité dans sa chambre si l'écran n'y est
            pas. Son sommeil gagnera en qualité. À table, privilégiez un moment
            déconnecté. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Choisissez un bon moment pour l'utilisation des écrans.</strong
            >
            S'il fait beau ou si votre enfant a encore des devoirs à faire, il
            vaut mieux remettre les écrans à plus tard. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Utilisez plutôt les écrans en famille ou entre ami-e-s.</strong
            >
            Quand on évoque les risques avec les écrans, l'isolement arrive dans
            les premiers. Car les écrans sont conçus pour accaparer toute notre
            attention. Utiliser les écrans en famille ou avec des ami-e-s et
            partager ces moments maintiennent les relations avec les autres.
            <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Offrez-vous des moments déconnectés.</strong
            >
            Dormir, manger, jouer, lire, sans les écrans allumés à proximité,
            permet de profiter pleinement de ces moments-là. De plus, ces
            espaces déconnectés nous montrent à tous que nous pouvons vivre
            sans.
          </p>
        </div>
        
        </v-card>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: 'Infos'
}
</script>

<style scoped>
.ecrans h2 {
  color: #62579d !important;
}
.ecrans p {
  color: #666666 !important;
}
.ecrans strong {
  color: #62579d !important;
}
.enfant h2 {
  color: #a6c700 !important;
}
.environnement h2 {
  color: #fbb700 !important;
}
.selected {
  background: #f1eabf;
  padding: 20px;
}

@media screen and (max-width: 599px) {
  .title-page-info {
    font-size: 25px !important;
  }
  .size-title-little {
    font-size: 25px !important;
  }
  .size-body-little {
    font-size: 16px !important;
  }

  .size-title-medium {
    font-size: 31px !important;
  }
  .size-body-medium {
    font-size: 18px !important;
  }

  .size-title-big {
    font-size: 40px !important;
  }
  .size-body-big {
    font-size: 20px !important;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

import apiCall from '@/utils/api.js'

export const LOAD = 'LOAD'
export const UNLOAD = 'UNLOAD'

Vue.use(Vuex)

const state = {
  feedback: localStorage.getItem('feedback') || ''
}

const getters = {
  getFeedback: state => state.feedback
}

const actions = {
  [LOAD]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      let data = new FormData()

      if(state.feedback){
        apiCall({ url: '/feedback/' + state.feedback, method: 'get' })
          .then(response => {
            if(response.data.status == '1'){
              apiCall({ url: '/feedback', method: 'post', data: {} })
                .then(response => {
                  localStorage.setItem('feedback', response.data.id)
                  commit(LOAD, response.data.id)
                  resolve()
                })
                .catch(err => {
                  commit(UNLOAD)
                  localStorage.removeItem('feedback')
                  reject(err)
                })
            }
          })
          .catch(err => {
            apiCall({ url: '/feedback', method: 'post', data: {} })
              .then(response => {
                localStorage.setItem('feedback', response.data.id)
                commit(LOAD, response.data.id)
                resolve()
              })
              .catch(err => {
                commit(UNLOAD)
                localStorage.removeItem('feedback')
                reject(err)
              })
          })
      }
      else {
        apiCall({ url: '/feedback', method: 'post', data: {} })
          .then(response => {
            localStorage.setItem('feedback', response.data.id)
            commit(LOAD, response.data.id)
            resolve()
          })
          .catch(err => {
            commit(UNLOAD)
            localStorage.removeItem('feedback')
            reject(err)
          })
      }

      
    })
  },
  [UNLOAD]: ({ commit }) => {
    return new Promise(resolve => {
      commit(UNLOAD)
      localStorage.removeItem('feedback')
      resolve()
    })
  }
}

const mutations = {
  [LOAD]: (state, feedback) => {
    state.feedback = feedback
  },
  [UNLOAD]: state => {
    state.feedback = ''
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

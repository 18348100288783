import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#62579D',
        secondary: '#A6C700',
        accent: '#FBB700'
      }
    }
  }
})

<template>
  <v-content class="backgroundImg">
    <v-container
      class="d-flex align-center justify-center"
      style="height: 100%"
    >
      <div
        class="ma-3 ma-sm-12 px-sm-7 py-sm-10 d-flex flex-column justify-center align-center white--text"
      >
        <h1 color="primary" class="text-center title-page">
          Notre test est anonyme
        </h1>
        <h2 class="text-center mb-12 subtitle-page" style="color:#333;">
          Vous pouvez toutefois, pour nos statistiques, nous indiquer l'âge et
          le sexe de votre enfant
        </h2>
        <v-card class="ma-sm-8 my-12 mt-0 px-8 py-5">
          <v-form ref="form" class="text-center">
            <div class="d-flex flex-column flex-sm-row">
              <div class="mr-sm-10">
                <v-select
                  max-width="150"
                  :items="['garçon', 'fille']"
                  :key="'sexe'"
                  v-model="sexe"
                  label="sexe de votre enfant"
                ></v-select>
              </div>
              <div>
                <v-text-field
                  suffix="ans"
                  v-model="age"
                  label="âge de votre enfant"
                ></v-text-field>
              </div>
            </div>

            <v-btn color="primary" class="my-4" @click="validate" dark x-large>
              Continuer
            </v-btn>
          </v-form>
        </v-card>
      </div>
    </v-container>
  </v-content>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      sexe: null,
      age: null
    }
  },
  methods: {
    validate() {
      this.$router.push('/questions/enfant')
    }
  }
}
</script>

<style scope>
h1,
h2,
p {
  color: #62579d;
}
@media screen and (max-width: 599px) {
  .title-page {
    font-size: 25px !important;
  }
  .subtitle-page {
    font-size: 19px;
    margin-top: 10px;
  }
}
</style>